@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$dummyBackground: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);

.split {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
    @include stretch(size("gutter"));

    .left {
      margin-right: size("gutter--huge") * 2;
    }

    & > div {
      padding: 0 size("gutter");
      &:first-child { 
        flex: 1 1 auto; 
        width: 100%;
      }
      &:last-child {
        flex: 1 0 370px;
        @media (max-width: (breakpoint("medium") - 1px)) and
               (min-width: breakpoint("small")) {
          flex: 1 0 340px;
        }
      }
    }
  }
}

.title {
  font-size: size("font--title");
}

.content {
  font-size: size("font--medium-small");
  line-height: font("line-height--medium-small");

  ul, ol {
    list-style-type: disc;
    list-style-position: inside;
    line-height: font("line-height");
  }

  ol {
    list-style-type: decimal;
  }

  li + li {
    margin-top: size("gutter");
  }
}

.items {
  @media (max-width: (breakpoint("small") - 1px)) {
    margin-top: size("gutter--large") * 2.5;
  }

  &Heading {
    font-size: size("font--large");
    font-family: var(--fontBody), var(--fontBodyFallback, #{font("fallback")});
  }

  & > * + * {
    margin-top: size("gutter");
  }
}

.date {
  border-top: solid 2px var(--border);
  padding-top: size("gutter");
  margin-top: size("gutter--huge");
  color: color("text--muted");
}

.image {
  width: 100%;
  aspect-ratio: 21 / 9;
  object-fit: cover;
  border-radius: var(--borderRadius);
  border: 0.5px solid rgba(color("border--muted"), 0.5);
  background-color: color("background");
  
}

.dummy {
  .top {
    position: relative;
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    max-height: 382px;
    background-image: $dummyBackground;
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .right p,
  .bottom p {
    display: block;
    width: 100%;
    background-image: $dummyBackground;
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  };

  .bottom {
    p {
      margin-bottom: size("gutter");
      height: size("font");

      &:first-child {
        width: 53%;
        height: size("font--enormous");
        margin-top: size("gutter");
      }
    }
  }

  .right {
    p {
      height: 242px;
      border-radius: var(--borderRadius);

      &:first-child {
        width: 36%;
        height: size("font--huge");
        margin-top: size("gutter");
        margin-bottom: size("gutter--large");
      }
    }
  }

  .imagePixel {
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    margin-bottom: size("gutter");
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}
