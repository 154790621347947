@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.block {
  background-color: var(--background);
  flex-grow: 1;
}

.intro {
  margin-bottom: size("gutter--large");
}

.innerBlock {
  max-width: breakpoint("small");
  margin: auto;
}

.image {
  margin-bottom: size("gutter");
}

.item {
  border-bottom: 1px solid color("border");
  margin-bottom: size("gutter--huge");
}

.contentWrapper {
  margin-bottom: size("gutter--huge");
  h2 {
    margin-top: size("gutter--mini");
    margin-bottom: size("gutter");
  }
  .date {
    font-size: size("font--mini");
  }
}

.no_news {
  padding-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
    padding-bottom: size("gutter--large");
  }
}

.dummy {
  .top {
    position: relative;
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    max-height: 382px;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .bottom {
    p {
      display: block;
      margin-bottom: size("gutter");
      background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
      background-size: 200%;
      animation: shine 1.5s infinite linear;
      height: size("font");
      width: 100%;

      &:first-child {
        width: 53%;
        height: size("font--large");
        margin-top: size("gutter");
      }
    }
  }
  .imagePixel {
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    margin-bottom: size("gutter");
  }
}
